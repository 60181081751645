import {
  Flex,
  Text,
  Box,
  Image,
  Divider,
  Button,
  SimpleGrid,
} from "@chakra-ui/react";
import { Input } from "../../../components/atoms/Input";
import * as yup from "yup";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import { api } from "../../../services/api";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useState } from "react";
import { createToast } from "../../../components/Feedback/Toast";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Logo from "../../../assets/logo_vertical.png";

const createCashierOutletFormSchema = yup.object().shape({
  name: yup
    .string()
    .required("Nome fantasia é obrigatório")
    .min(3, "O nome fantasia deve conter no mínimo 3 caracteres"),
  postalCode: yup
    .string()
    .required("O CEP é obrigatório")
    .matches(/(\d{5})-(\d{3})/gm, "CEP inválido")
    .min(9, "O CEP deve conter 9 caracteres")
    .max(9, "O CEP deve conter 9 caracteres"),
  city: yup
    .string()
    .required("A cidade é obrigatória")
    .min(3, "A cidade deve conter no mínimo 3 caracteres"),
  state: yup
    .string()
    .required("O estado é obrigatória")
    .matches(/[a-zA-Z]{2}/gm, "Digite somente a sigla do Estado")
    .min(2, "O estado deve conter 2 caracteres")
    .max(2, "O estado deve conter 2 caracteres"),
});
export function CreateClientWhiteLabel() {
  const { reseller_id } = useParams();
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, formState, setValue } = useForm({
    mode: "all",
    resolver: yupResolver(createCashierOutletFormSchema),
  });

  const handleGetCep = useCallback(
    (cep: string) => {
      axios
        .get(`https://viacep.com.br/ws/${cep.replace("-", "")}/json`)
        .then((response) => {
          setValue("city", response.data.localidade);
          setValue("state", response.data.uf);
        });
    },
    [setValue]
  );

  const handleCreateClients: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      try {
        await api
          .post("/companies/reseller", {
            state: formValue.state,
            city: formValue.city,
            name: formValue.name,
            reseller_id,
          })
          .then((response) => {
            setLoading(true);
            createToast({
              title: "Cadastro realizado com sucesso!",
              status: "success",
            });
            window.location.href = response.data.link;
          });
      } catch (err: any) {
        setLoading(false);
        createToast({
          title: err.response.data.message,
          status: "error",
        });
      }
    },
    [reseller_id]
  );

  const { errors } = formState;
  return (
    <>
      <Helmet encodeSpecialCharacters={true}>
        <script>
          {`<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=AW-350401023"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'AW-350401023');
</script>`}
        </script>
        <script>
          {`
          {!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}
          (window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js'); fbq('init',
          '348208049707056'); fbq('track', 'PageView');
          fbq('track', 'InitiateCheckout');
        `}
        </script>
        <noscript>
          {`<img
            height="1"
            width="1"
            style={{
              display: "none",
            }}
            src="https://www.facebook.com/tr?id=348208049707056&ev=PageView&noscript=1"
          />`}
        </noscript>
        <script>
          {`function gtag_report_conversion(url) {
  var callback = function () {
    if (typeof(url) != 'undefined') {
      window.location = url;
    }
  };
  gtag('event', 'conversion', {
      'send_to': 'AW-350401023/3v2vCOrO5oUYEP_jiqcB',
      'event_callback': callback
  });
  return false;
}`}
        </script>

        <title>Checkout Falaê!</title>
      </Helmet>
      <Flex alignItems="stretch" bg="gray.100" minH="100vh" wrap="wrap">
        <Flex
          textAlign="center"
          align="center"
          justify="center"
          flexDir="column"
          margin="auto"
          my={{ base: 10 }}
        >
          <Image
            src={Logo}
            alt="Logo Experiência Falaê!"
            maxW="250"
            maxH="170"
            loading="lazy"
            mb={10}
          />

          <Text color="#2b88a1" maxW="350px" fontWeight="bold">
            {String(
              "Monitore a Satisfação do seu Cliente em Tempo Real!"
            ).toUpperCase()}
          </Text>
        </Flex>
        <Flex
          flexDir="column"
          placeContent="center"
          align="center"
          maxW="700"
          margin="auto"
          as="form"
          w="100%"
          onSubmit={handleSubmit(handleCreateClients)}
          id="add-form"
        >
          <Flex
            width="100%"
            flexDir="column"
            align="center"
            justify="center"
            bg="white"
            p="5"
            borderRadius="10px"
            shadow="md"
          >
            <Box>
              <Text
                color="#2b88a1"
                fontSize="26px"
                fontWeight="bold"
                textAlign="center"
              >
                Seja bem-vindo!
              </Text>
            </Box>
            <Divider borderBottom="2px solid #ff9000" my="2" />
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={2} w="100%">
              <Input
                label="Nome fantasia"
                isRequired={true}
                error={errors.name}
                {...register("name")}
              />
              <Input
                label="CEP"
                isRequired={true}
                error={errors.postalCode}
                {...register("postalCode")}
                maxLength={9}
                onChange={(e) => {
                  var value = e.target.value;
                  value = value
                    .replace(/\D+/g, "")
                    .replace(/(\d{5})(\d)/, "$1-$2");
                  e.target.value = value;
                  setValue("postalCode", value);
                }}
                onBlur={(e) => handleGetCep(e.target.value)}
              />
              <Input
                label="Cidade"
                isRequired={true}
                error={errors.city}
                {...register("city")}
              />
              <Input
                label="Estado"
                isRequired={true}
                error={errors.state}
                {...register("state")}
              />
            </SimpleGrid>
            <Button
              mt="10px"
              d="block"
              w="100%"
              colorScheme={"blue"}
              type="submit"
              form="add-form"
              isLoading={formState.isSubmitting || loading}
            >
              Concluir cadastro
            </Button>
            <Divider borderBottom="2px solid #ff9000" my="2" />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
