import {
  Flex,
  Text,
  Box,
  Image,
  Divider,
  Button,
  CheckboxGroup,
  Checkbox,
  SimpleGrid,
  Select,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import { Input } from "../../components/atoms/Input";
import * as yup from "yup";
import { PrivacityText } from "../../components/atoms/Text/PrivacityText";
import {
  useForm,
  SubmitHandler,
  FieldValues,
  Controller,
} from "react-hook-form";
import { api } from "../../services/api";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createToast } from "../../components/Feedback/Toast";
import axios from "axios";
import { useParams } from "react-router-dom";
import { FormControl } from "../../components/FormControl";
import { FormControlSelect } from "../../components/molecules/FormControl/FormControlSelect";
import { formatMooney } from "../../utils/mooney";
import { translateMonthDate } from "../../utils/helper";
import { Helmet } from "react-helmet";
import { formatPlanType } from "../../utils/formatPlanType";
import Logo from "../../assets/logo_vertical.png";
interface PaymentLink {
  id: string;
  billing_type: string;
  name: string;
  description: string;
  value: number;
  discounted_installments?: number;
  total_months_discounted_installments?: number;
  payment_type: string;
  installments?: number;
  cycle?:
    | "WEEKLY"
    | "BIWEEKLY"
    | "MONTHLY"
    | "QUARTERLY"
    | "SEMIANNUALLY"
    | "YEARLY";
  months_to_first_payment: number;
  plan_type?: "MONTHLY" | "QUARTERLY" | "SEMIANNUALLY" | "YEARLY";
}

const createCashierOutletFormSchema = yup.object().shape({
  name: yup
    .string()
    .required("Nome fantasia é obrigatório")
    .min(3, "O nome fantasia deve conter no mínimo 3 caracteres"),
  corporate_name: yup
    .string()
    .required("Razão social é obrigatório")
    .min(3, "A razão social deve conter no mínimo  3 caracteres"),
  cnpj: yup
    .string()
    .required("O CNPJ é obrigatório")
    .matches(/(\d{2}).(\d{3}).(\d{3})\/(\d{4})-(\d{2})/gm, "CNPJ inválido")
    .min(18, "O CNPJ deve conter 18 caracteres")
    .max(18, "O CNPJ deve conter 18 caracteres"),
  postalCode: yup
    .string()
    .required("O CEP é obrigatório")
    .matches(/(\d{5})-(\d{3})/gm, "CEP inválido")
    .min(9, "O CEP deve conter 9 caracteres")
    .max(9, "O CEP deve conter 9 caracteres"),
  city: yup
    .string()
    .required("A cidade é obrigatória")
    .min(3, "A cidade deve conter no mínimo 3 caracteres"),
  province: yup
    .string()
    .required("O bairro é obrigatório")
    .min(3, "O bairro deve conter no mínimo 3 caracteres"),
  state: yup
    .string()
    .required("O estado é obrigatória")
    .matches(/[a-zA-Z]{2}/gm, "Digite somente a sigla do Estado")
    .min(2, "O estado deve conter 2 caracteres")
    .max(2, "O estado deve conter 2 caracteres"),
  address: yup
    .string()
    .required("O logradouro é obrigatório")
    .min(3, "O logradouro deve conter no mínimo 3 caracteres"),
  addressNumber: yup
    .number()
    .typeError("O campo deve ser do formato numérico")
    .required("O número é obrigatório"),
  complement: yup.string().nullable(),

  nameHolderInfo: yup
    .string()
    .required("Nome completo do responsável é obrigatório")
    .min(3, "O completo do responsável deve conter no mínimo 3 caracteres"),
  emailHolderInfo: yup
    .string()
    .required("E-mail é obrigatório")
    .email("E-mail inválido"),
  mobilePhone: yup
    .string()
    .required("Telefone é obrigatório")
    .matches(/\(\d{2}\) \d{5}-\d{4}/gm, "Formato inválido"),
  days_for_payment: yup.string().required("Dia para o pagamento é obrigatório"),
  first_option: yup.string().when("billing_type", {
    is: "CARTÃO",
    then: yup
      .string()
      .required("Número do cartão é obrigatório")
      .min(14, "O número do cartão deve conter no mínimo 14 caracteres")
      .max(16, "O número do cartão deve conter no máximo 16 caracteres"),
  }),
  second_option: yup.string().when("billing_type", {
    is: "CARTÃO",
    then: yup
      .string()
      .required("Nome do cartão é obrigatório")
      .min(3, "O número do cartão deve conter 3 caracteres"),
  }),
  third_option: yup.string().when("billing_type", {
    is: "CARTÃO",
    then: yup
      .string()
      .required("O Mês do vencimento do cartão é obrigatório")
      .min(2, "O mês do vencimento do cartão deve conter 2 caracteres")
      .max(2, "O mês do vencimento do cartão deve conter 2 caracteres"),
  }),
  fourth_option: yup.string().when("billing_type", {
    is: "CARTÃO",
    then: yup
      .string()
      .required("O ano do vencimento do cartão é obrigatório")
      .min(4, "O ano do vencimento do cartão deve conter 4 caracteres")
      .max(4, "O ano do vencimento do cartão deve conter 4 caracteres"),
  }),
  fifth_option: yup.string().when("billing_type", {
    is: "CARTÃO",
    then: yup
      .string()
      .required("O CVV do cartão é obrigatório")
      .min(3, "O CVV do cartão deve conter 3 caracteres")
      .max(3, "O CVV do cartão deve conter 3 caracteres"),
  }),
  postalCodeFinancialResponsible: yup.string().when("billing_type", {
    is: "CARTÃO",
    then: yup
      .string()
      .required("O CEP é obrigatório")
      .matches(/(\d{5})-(\d{3})/gm, "CEP inválido")
      .min(9, "O CEP deve conter 9 caracteres"),
  }),

  addressNumberFinancialResponsible: yup
    .number()
    .typeError("O campo deve ser do formato numérico")
    .when("billing_type", {
      is: "CARTÃO",
      then: yup
        .number()
        .typeError("O campo deve ser do formato numérico")
        .required("O número é obrigatório"),
    }),
  complementFinancialResponsible: yup.string().when("billing_type", {
    is: "CARTÃO",
    then: yup.string().nullable(),
  }),
  approved: yup
    .bool()
    .oneOf(
      [true],
      "O consentimento aos Termos de Uso e Política de Privaicidade é obrigatório"
    ),
});
export function CreateClient() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [paymentLink, setPaymentLink] = useState<PaymentLink>(
    {} as PaymentLink
  );
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    control,
    trigger,
    getValues,
  } = useForm({
    mode: "all",
    resolver: yupResolver(createCashierOutletFormSchema),
  });
  const today = new Date();
  const [steps, setSteps] = useState({
    details: true,
    company: false,
    responsible: false,
    payment: false,
    summary: false,
  });
  const [position, setPosition] = useState(0);
  const handleGetCep = useCallback(
    (cep: string) => {
      axios
        .get(`https://viacep.com.br/ws/${cep.replace("-", "")}/json`)
        .then((response) => {
          setValue("city", response.data.localidade);
          setValue("state", response.data.uf);
          setValue("province", response.data.bairro);
          setValue("address", response.data.logradouro);
        });
    },
    [setValue]
  );

  const handleCreateClients: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      try {
        await api.post("/companies/asaas/rd", {
          approved: formValue.approved,
          billing_type: formValue.billing_type,
          fifth_option: formValue.fifth_option,
          fourth_option: formValue.fourth_option,
          third_option: formValue.third_option,
          second_option: formValue.second_option,
          first_option: formValue.first_option,
          days_for_payment: formValue.days_for_payment,
          mobilePhone: formValue.mobilePhone,
          emailHolderInfo: formValue.emailHolderInfo,
          nameHolderInfo: formValue.nameHolderInfo,
          complement: formValue.complement,
          addressNumber: formValue.addressNumber,
          address: formValue.address,
          state: formValue.state,
          province: formValue.province,
          city: formValue.city,
          postalCode: formValue.postalCode,
          complementFinancialResponsible:
            formValue.complementFinancialResponsible,
          addressNumberFinancialResponsible:
            formValue.addressNumberFinancialResponsible,
          postalCodeFinancialResponsible:
            formValue.postalCodeFinancialResponsible,
          cnpj: formValue.cnpj,
          corporate_name: formValue.corporate_name,
          name: formValue.name,
          link_id: id,
        });
        setLoading(true);
        createToast({
          title: "Cadastro realizado com sucesso!",
          status: "success",
        });
        window.location.href =
          "https://conteudo.falae.app/obg-cadastro-de-clientes-checkout";
      } catch (err: any) {
        setLoading(false);
        createToast({
          title: err.response.data.message,
          status: "error",
        });
      }
    },
    [id]
  );
  const cycle = useMemo(() => {
    switch (paymentLink.cycle) {
      case "WEEKLY":
        return "semanal";
      case "BIWEEKLY":
        return "quinzenal - 2 semanas";
      case "MONTHLY":
        return "mensal";
      case "QUARTERLY":
        return "trimestral";
      case "SEMIANNUALLY":
        return "semestral";
      case "YEARLY":
        return "anual";
    }
  }, [paymentLink.cycle]);
  useEffect(() => {
    api.get(`/paymentLinks/${id}`).then((response) => {
      setValue("billing_type", response.data.billing_type);
      setPaymentLink(response.data);
    });
  }, [id, setValue]);

  const formatMonthToFirstPayment = useMemo(() => {
    const now = new Date();
    if (paymentLink.months_to_first_payment) {
      const formatMonthDate = new Date(
        now.setMonth(now.getMonth() + paymentLink.months_to_first_payment)
      );
      return `${translateMonthDate(
        formatMonthDate.getMonth() + 1
      )} de ${formatMonthDate.getFullYear()}`;
    }
    return "";
  }, [paymentLink.months_to_first_payment]);

  const allDaysText = useCallback((days: number) => {
    const formatDate = new Date();
    return ` Todo dias ${new Date(
      formatDate.setDate(formatDate.getDate() + days)
    ).getDate()} do mês`;
  }, []);
  const { errors } = formState;
  return (
    <>
      <Helmet encodeSpecialCharacters={true}>
        <script>
          {`<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=AW-350401023"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'AW-350401023');
</script>`}
        </script>
        <script>
          {`
          {!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}
          (window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js'); fbq('init',
          '348208049707056'); fbq('track', 'PageView');
          fbq('track', 'InitiateCheckout');
        `}
        </script>
        <noscript>
          {`<img
            height="1"
            width="1"
            style={{
              display: "none",
            }}
            src="https://www.facebook.com/tr?id=348208049707056&ev=PageView&noscript=1"
          />`}
        </noscript>
        <script>
          {`function gtag_report_conversion(url) {
  var callback = function () {
    if (typeof(url) != 'undefined') {
      window.location = url;
    }
  };
  gtag('event', 'conversion', {
      'send_to': 'AW-350401023/3v2vCOrO5oUYEP_jiqcB',
      'event_callback': callback
  });
  return false;
}`}
        </script>

        <title>Checkout Falaê!</title>
      </Helmet>
      <Flex alignItems="stretch" bg="gray.100" minH="100vh" wrap="wrap">
        <Flex
          textAlign="center"
          align="center"
          justify="center"
          flexDir="column"
          margin="auto"
          my={{ base: 10 }}
        >
          <Image
            src={Logo}
            alt="Logo Experiência Falaê!"
            maxW="250"
            maxH="170"
            loading="lazy"
            mb={10}
          />

          <Text color="#2b88a1" maxW="350px" fontWeight="bold">
            {String(
              "Monitore a Satisfação do seu Cliente em Tempo Real!"
            ).toUpperCase()}
          </Text>
        </Flex>
        <Flex
          flexDir="column"
          placeContent="center"
          align="center"
          maxW="700"
          margin="auto"
          as="form"
          w="100%"
          onSubmit={handleSubmit(handleCreateClients)}
          id="add-form"
        >
          <Flex
            width="100%"
            flexDir="column"
            align="center"
            justify="center"
            bg="white"
            p="5"
            borderRadius="10px"
            shadow="md"
          >
            <Box>
              <Text
                color="#2b88a1"
                fontSize="26px"
                fontWeight="bold"
                textAlign="center"
              >
                Seja bem-vindo!
              </Text>
            </Box>
            <Divider borderBottom="2px solid #ff9000" my="2" />
            <Tabs isFitted variant="enclosed" w="100%" index={position}>
              <TabList
                mb="1em"
                overflowY="hidden"
                overflowX={{ base: "scroll", md: "hidden" }}
              >
                <Tab onClick={() => setPosition(0)} isDisabled={!steps.details}>
                  Detalhes
                </Tab>
                <Tab onClick={() => setPosition(1)} isDisabled={!steps.company}>
                  Empresa
                </Tab>
                <Tab
                  onClick={() => setPosition(2)}
                  isDisabled={!steps.responsible}
                >
                  Responsável
                </Tab>
                <Tab onClick={() => setPosition(3)} isDisabled={!steps.payment}>
                  Pagamento
                </Tab>
                <Tab onClick={() => setPosition(4)} isDisabled={!steps.summary}>
                  Resumo
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={2} w="100%">
                    <Text fontWeight="bold">Produto:</Text>
                    <Text>
                      {paymentLink.payment_type === "assinatura"
                        ? "Assinatura"
                        : "Cobrança"}{" "}
                      Software Falaê
                    </Text>
                    {paymentLink.plan_type ? (
                      <>
                        <Text fontWeight="bold">Plano:</Text>
                        <Text>{formatPlanType(paymentLink.plan_type)}</Text>
                      </>
                    ) : (
                      <></>
                    )}

                    <Text fontWeight="bold">Descrição:</Text>
                    <Text>{paymentLink.description}</Text>
                    {paymentLink.payment_type === "assinatura" ? (
                      <>
                        <Text fontWeight="bold">Valor:</Text>
                        <Text>
                          {formatMooney(paymentLink.value)} ({cycle})
                        </Text>
                        {paymentLink.total_months_discounted_installments &&
                        paymentLink.total_months_discounted_installments > 0 &&
                        paymentLink.discounted_installments ? (
                          <>
                            <Text fontWeight="bold">Desconto:</Text>
                            <Text>
                              {paymentLink.total_months_discounted_installments >
                              1
                                ? `${paymentLink.total_months_discounted_installments} primeiros meses `
                                : `Primeiro mês `}
                              à{" "}
                              {formatMooney(
                                paymentLink.discounted_installments
                              )}
                            </Text>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <>
                        <Text fontWeight="bold">Valor total:</Text>
                        <Text>{formatMooney(paymentLink.value)}</Text>
                        {paymentLink.installments ? (
                          <>
                            <Text fontWeight="bold">Valor Parcelado:</Text>
                            <Text>
                              {paymentLink.installments}x de{" "}
                              {formatMooney(
                                paymentLink.value / paymentLink.installments
                              )}
                            </Text>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                    {paymentLink.months_to_first_payment > 0 ? (
                      <>
                        <Text fontWeight="bold">Primeiro Pagamento: </Text>
                        <Text>Somente em {formatMonthToFirstPayment}</Text>
                      </>
                    ) : (
                      <></>
                    )}
                    <Text fontWeight="bold">Forma de pagamento:</Text>
                    <Text>
                      {paymentLink.billing_type === "BOLETO"
                        ? "Boleto Bancário"
                        : "Cartão de Crédito"}{" "}
                    </Text>
                  </SimpleGrid>
                  <Divider borderBottom="2px solid #ff9000" my="2" />
                  <Button
                    mt="10px"
                    d="block"
                    w="100%"
                    colorScheme={"blue"}
                    onClick={() => {
                      setSteps({
                        ...steps,
                        company: true,
                      });
                      setPosition(1);
                    }}
                  >
                    Contratar
                  </Button>
                </TabPanel>
                <TabPanel>
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={2} w="100%">
                    <Input
                      label="Nome fantasia"
                      isRequired={true}
                      error={errors.name}
                      {...register("name")}
                    />
                    <Input
                      label="Razão social"
                      isRequired={true}
                      error={errors.corporate_name}
                      {...register("corporate_name")}
                    />
                    <Input
                      label="CNPJ"
                      isRequired={true}
                      error={errors.cnpj}
                      {...register("cnpj")}
                      maxLength={20}
                      onChange={(e) => {
                        var value = e.target.value;
                        value = value
                          .replace(/\D+/g, "")
                          .replace(/(\d{2})(\d)/, "$1.$2")
                          .replace(/(\d{3})(\d)/, "$1.$2")
                          .replace(/(\d{3})(\d)/, "$1/$2")
                          .replace(/(\d{4})(\d)/, "$1-$2")
                          .replace(/(-\d{2})\d+?$/, "$1");
                        e.target.value = value;
                        setValue("cnpj", value);
                      }}
                    />
                    <Input
                      label="CEP"
                      isRequired={true}
                      error={errors.postalCode}
                      {...register("postalCode")}
                      maxLength={9}
                      onChange={(e) => {
                        var value = e.target.value;
                        value = value
                          .replace(/\D+/g, "")
                          .replace(/(\d{5})(\d)/, "$1-$2");
                        e.target.value = value;
                        setValue("postalCode", value);
                      }}
                      onBlur={(e) => handleGetCep(e.target.value)}
                    />
                    <Input
                      label="Cidade"
                      isRequired={true}
                      error={errors.city}
                      {...register("city")}
                    />
                    <Input
                      label="Estado"
                      isRequired={true}
                      error={errors.state}
                      {...register("state")}
                    />
                    <Input
                      label="Bairro"
                      isRequired={true}
                      error={errors.province}
                      {...register("province")}
                    />
                    <Input
                      label="Logradouro"
                      isRequired={true}
                      error={errors.address}
                      {...register("address")}
                    />
                    <Input
                      label="Número"
                      isRequired={true}
                      error={errors.addressNumber}
                      {...register("addressNumber")}
                      onChange={(e) => {
                        var value = e.target.value;
                        value = value.replace(/\D/g, "");
                        e.target.value = value;
                      }}
                    />
                    <Input
                      label="Complemento"
                      isRequired={false}
                      error={errors.complement}
                      {...register("complement")}
                    />
                  </SimpleGrid>
                  <Button
                    mt="10px"
                    d="block"
                    w="100%"
                    colorScheme={"blue"}
                    onClick={async () => {
                      const result = await trigger([
                        "name",
                        "corporate_name",
                        "cnpj",
                        "postalCode",
                        "city",
                        "state",
                        "province",
                        "address",
                        "addressNumber",
                        "complement",
                      ]);
                      if (result) {
                        setSteps({
                          ...steps,
                          responsible: true,
                        });
                        setPosition(2);
                      }
                    }}
                  >
                    Próximo
                  </Button>
                  <Divider borderBottom="2px solid #ff9000" my="2" />
                </TabPanel>
                <TabPanel>
                  <SimpleGrid columns={1} spacing={2} w="100%">
                    <Input
                      label="Nome completo do responsável"
                      isRequired={true}
                      error={errors.name}
                      {...register("nameHolderInfo")}
                    />
                    <Input
                      label={`E-mail ${
                        paymentLink.billing_type === "BOLETO"
                          ? "(Para envio do boleto por E-mail)"
                          : ""
                      }`}
                      isRequired={true}
                      type="email"
                      {...register("emailHolderInfo")}
                    />

                    <Input
                      label={`Celular ${
                        paymentLink.billing_type === "BOLETO"
                          ? "(Para envio do boleto por SMS)"
                          : ""
                      }`}
                      isRequired={true}
                      error={errors.mobilePhone}
                      {...register("mobilePhone")}
                      maxLength={15}
                      onChange={(e) => {
                        var value = e.target.value;
                        value = value.replace(/\D/g, "");
                        value = value.replace(/^(\d{2})(\d)/g, "($1) $2");
                        value = value.replace(/(\d)(\d{4})$/, "$1-$2");
                        e.target.value = value;
                        setValue("mobilePhone", value);
                      }}
                    />
                  </SimpleGrid>
                  <Divider borderBottom="2px solid #ff9000" my="2" />
                  <Button
                    mt="10px"
                    d="block"
                    w="100%"
                    colorScheme={"blue"}
                    onClick={async () => {
                      const result = await trigger([
                        "nameHolderInfo",
                        "emailHolderInfo",
                        "mobilePhone",
                      ]);
                      if (result) {
                        setSteps({
                          ...steps,
                          payment: true,
                        });
                        setPosition(3);
                      }
                    }}
                  >
                    Próximo
                  </Button>
                </TabPanel>
                <TabPanel>
                  <SimpleGrid columns={{ base: 1 }} spacing={2} w="100%">
                    <FormControl
                      name="days_for_payment"
                      isRequired={true}
                      label={`Dia para o pagamento ${
                        paymentLink.months_to_first_payment === 0
                          ? ""
                          : `- Primeiro pagamento somente em ${formatMonthToFirstPayment}`
                      }`}
                      error={errors.days_for_payment}
                    >
                      <Controller
                        control={control}
                        name="days_for_payment"
                        render={({ field }) => (
                          <Select {...field} placeholder="Selecione">
                            {paymentLink.months_to_first_payment === 0 ? (
                              <>
                                <option value={3}>Após 3 dias</option>
                                <option value={5}>Após 5 dias</option>
                                <option value={7}>Após 7 dias</option>
                                <option value={10}>Após 10 dias</option>
                              </>
                            ) : (
                              <>
                                <option value={3}>{allDaysText(3)}</option>
                                <option value={5}>{allDaysText(5)}</option>
                                <option value={7}>{allDaysText(7)}</option>
                                <option value={10}>{allDaysText(10)}</option>
                              </>
                            )}
                          </Select>
                        )}
                      />
                    </FormControl>
                    {paymentLink.billing_type === "CARTÃO" && (
                      <>
                        <Input
                          label="Número do cartão"
                          isRequired={true}
                          error={errors.first_option}
                          {...register("first_option")}
                          maxLength={16}
                          onChange={(e) => {
                            var value = e.target.value;
                            value = value.replace(/\D+/g, "");
                            e.target.value = value;
                            setValue("first_option", value);
                          }}
                        />
                        <Input
                          label="Nome no cartão"
                          error={errors.second_option}
                          isRequired={true}
                          type="text"
                          {...register("second_option")}
                          onChange={(e) => {
                            var value = e.target.value;
                            setValue("second_option", value.toUpperCase());
                          }}
                        />
                        <SimpleGrid columns={{ base: 1, md: 2 }} spacingX={1}>
                          <FormControlSelect
                            control={control}
                            label="Mês"
                            error={errors.third_option}
                            placeholder="Mês"
                            {...register("third_option")}
                            options={[
                              {
                                label: "01",
                                value: "01",
                              },
                              {
                                label: "02",
                                value: "02",
                              },
                              {
                                label: "03",
                                value: "03",
                              },
                              {
                                label: "04",
                                value: "04",
                              },
                              {
                                label: "05",
                                value: "05",
                              },
                              {
                                label: "06",
                                value: "06",
                              },
                              {
                                label: "07",
                                value: "07",
                              },
                              {
                                label: "08",
                                value: "08",
                              },
                              {
                                label: "09",
                                value: "09",
                              },
                              {
                                label: "10",
                                value: "10",
                              },
                              {
                                label: "11",
                                value: "11",
                              },
                              {
                                label: "12",
                                value: "12",
                              },
                            ]}
                            isRequired={true}
                          />
                          <FormControlSelect
                            control={control}
                            label="Ano"
                            placeholder="Ano"
                            error={errors.fourth_option}
                            {...register("fourth_option")}
                            options={[
                              {
                                label: "2023",
                                value: "2023",
                              },
                              {
                                label: "2024",
                                value: "2024",
                              },
                              {
                                label: "2025",
                                value: "2025",
                              },
                              {
                                label: "2026",
                                value: "2026",
                              },
                              {
                                label: "2027",
                                value: "2027",
                              },
                              {
                                label: "2028",
                                value: "2028",
                              },
                              {
                                label: "2029",
                                value: "2029",
                              },
                              {
                                label: "2030",
                                value: "2030",
                              },
                              {
                                label: "2031",
                                value: "2031",
                              },
                              {
                                label: "2032",
                                value: "2032",
                              },
                              {
                                label: "2033",
                                value: "2033",
                              },
                              {
                                label: "2034",
                                value: "2034",
                              },
                            ]}
                            isRequired={true}
                          />
                        </SimpleGrid>
                        <Input
                          label="CVV"
                          isRequired={true}
                          type="text"
                          {...register("fifth_option")}
                          error={errors.fifth_option}
                          maxLength={3}
                          onChange={(e) => {
                            var value = e.target.value;
                            value = value.replace(/\D/g, "");
                            e.target.value = value;
                            setValue("fifth_option", value);
                          }}
                        />
                        <Input
                          label="CEP"
                          isRequired={true}
                          error={errors.postalCodeFinancialResponsible}
                          {...register("postalCodeFinancialResponsible")}
                          maxLength={9}
                          onChange={(e) => {
                            var value = e.target.value;
                            value = value
                              .replace(/\D+/g, "")
                              .replace(/(\d{5})(\d)/, "$1-$2");
                            e.target.value = value;
                            setValue("postalCodeFinancialResponsible", value);
                          }}
                        />
                        <Input
                          label="Número"
                          isRequired={true}
                          error={errors.addressNumberFinancialResponsible}
                          {...register("addressNumberFinancialResponsible")}
                          onChange={(e) => {
                            var value = e.target.value;
                            value = value.replace(/\D/g, "");
                            e.target.value = value;
                          }}
                        />
                        <Input
                          label="Complemento"
                          isRequired={false}
                          error={errors.complementFinancialResponsible}
                          {...register("complementFinancialResponsible")}
                        />
                      </>
                    )}

                    <CheckboxGroup>
                      <Flex alignItems="start" justify="start" flexDir="column">
                        <Checkbox
                          {...register("approved")}
                          isRequired={true}
                          variant=""
                          borderColor="#2b88a1"
                          isInvalid={!!errors.approved}
                        >
                          <PrivacityText />
                        </Checkbox>
                      </Flex>
                      <Input
                        isRequired={true}
                        type="hidden"
                        name=""
                        error={errors.approved}
                      />
                    </CheckboxGroup>
                  </SimpleGrid>
                  <Divider borderBottom="2px solid #ff9000" my="2" />
                  <Button
                    mt="10px"
                    d="block"
                    w="100%"
                    colorScheme={"blue"}
                    onClick={async () => {
                      const result = await trigger([
                        "days_for_payment",
                        "first_option",
                        "second_option",
                        "third_option",
                        "fourth_option",
                        "fifth_option",
                        "approved",
                        "addressNumberFinancialResponsible",
                        "complementFinancialResponsible",
                        "postalCodeFinancialResponsible",
                      ]);
                      if (result) {
                        setSteps({
                          ...steps,
                          summary: true,
                        });
                        setPosition(4);
                      }
                    }}
                  >
                    Próximo
                  </Button>
                </TabPanel>
                <TabPanel>
                  <SimpleGrid
                    columns={{ base: 1, md: 2 }}
                    spacing={2}
                    w="100%"
                    mb="2"
                  >
                    <Text fontWeight="bold">Produto:</Text>
                    <Text>
                      {paymentLink.payment_type === "assinatura"
                        ? "Assinatura"
                        : "Cobrança"}{" "}
                      Software Falaê
                    </Text>
                    {paymentLink.plan_type ? (
                      <>
                        <Text fontWeight="bold">Plano:</Text>
                        <Text>{formatPlanType(paymentLink.plan_type)}</Text>
                      </>
                    ) : (
                      <></>
                    )}
                    <Text fontWeight="bold">Descrição:</Text>
                    <Text>{paymentLink.description}</Text>
                    {paymentLink.payment_type === "assinatura" ? (
                      <>
                        <Text fontWeight="bold">Valor:</Text>
                        <Text>
                          {formatMooney(paymentLink.value)} ({cycle})
                        </Text>
                        {paymentLink.total_months_discounted_installments &&
                        paymentLink.total_months_discounted_installments > 0 &&
                        paymentLink.discounted_installments ? (
                          <>
                            <Text fontWeight="bold">Desconto:</Text>
                            <Text>
                              {paymentLink.total_months_discounted_installments}
                              {paymentLink.total_months_discounted_installments >
                              1
                                ? " primeiros meses "
                                : " primeiro mês "}
                              à{" "}
                              {formatMooney(
                                paymentLink.discounted_installments
                              )}
                            </Text>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <>
                        <Text fontWeight="bold">Valor total:</Text>
                        <Text>{formatMooney(paymentLink.value)}</Text>
                        {paymentLink.installments ? (
                          <>
                            <Text fontWeight="bold">Valor Parcelado:</Text>
                            <Text>
                              {paymentLink.installments}x de{" "}
                              {formatMooney(
                                paymentLink.value / paymentLink.installments
                              )}
                            </Text>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}

                    <Text fontWeight="bold">Data do Pagamento:</Text>
                    <Text>
                      Todo dia{" "}
                      {new Date(
                        today.setDate(
                          today.getDate() +
                            Number(getValues("days_for_payment"))
                        )
                      ).getDate()}{" "}
                      do mês
                    </Text>
                    {paymentLink.months_to_first_payment > 0 ? (
                      <>
                        <Text fontWeight="bold">Primeiro Pagamento: </Text>
                        <Text>Somente em {formatMonthToFirstPayment}</Text>
                      </>
                    ) : (
                      <></>
                    )}
                    <Text fontWeight="bold">Forma de pagamento:</Text>
                    <Text>
                      {paymentLink.billing_type === "BOLETO"
                        ? "Boleto Bancário"
                        : "Cartão de Crédito"}{" "}
                    </Text>
                  </SimpleGrid>
                  <Divider borderBottom="2px solid #ff9000" my="2" />
                  <Button
                    mt="10px"
                    d="block"
                    w="100%"
                    colorScheme={"blue"}
                    type="submit"
                    form="add-form"
                    isLoading={formState.isSubmitting || loading}
                  >
                    Concluir cadastro
                  </Button>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
